<template>
  <Disclosure v-slot="{ open }" as="nav" class="bg-white border-b border-gray-200">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <Link :href="route('home')">
              <Logo/>
            </Link>
          </div>
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <Link v-if="!user"
                  v-for="item in navigation"
                  :key="item.name"
                  :class="[item.route === $page.props.route.name ?
                                'border-dbblue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']"
                  :href="route(item.route)"
            >
              {{ item.name }}
            </Link>

            <Link v-if="user"
                  v-for="item in authenticatedUserNavigation"
                  :key="item.name"
                  :class="[item.route === $page.props.route.name ?
                                'border-dbblue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']"
                  :href="route(item.route)"
            >
              {{ item.name }}
            </Link>

            <Link v-if="user && userGroup === 'manager'"
                  v-for="item in managerNavigation"
                  :key="item.name"
                  :class="[item.route === $page.props.route.name ?
                                'border-dbblue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']"
                  :href="route(item.route)"
            >
              {{ item.name }}
            </Link>

            <Link v-if="user && userGroup === 'administrator'"
                  v-for="item in adminNavigation"
                  :key="item.name"
                  :class="[item.route === $page.props.route.name ?
                                'border-dbblue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']"
                  :href="route(item.route)"
            >
              {{ item.name }}
            </Link>
          </div>
        </div>

        <div class="hidden sm:ml-6 sm:flex sm:items-center z-10">
          <Menu as="div" class="ml-3 relative">
            <div v-if="!user" class="flex-shrink-0">
              <Link :href="route('auth.login')">Login</Link>
            </div>

            <div v-else class="flex justify-center items-center">

              <div>
                <MenuButton class="flex items-center">
                  <span class="sr-only">Open user menu</span>

                  <div class="hidden mr-2 text-xs lg:flex flex-col items-end justify-center">
                    <div class="flex-col space-x-1 font-semibold">
                      <span>
                        {{ user.firstname }}
                      </span>

                      <span>
                        {{ user.lastname }}
                      </span>
                    </div>

                    <span>{{ user.email }}</span>
                  </div>

                  <img :src="userProfile.imageUrl" alt="" class="h-8 w-8 rounded-full shrink-0"/>
                </MenuButton>

                <transition enter-active-class="transition ease-out duration-200"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                      class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                      <Link :href="item.href" class="block px-4 py-2 text-sm text-gray-700">
                        {{ item.name }}
                      </Link>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </div>

            </div>
          </Menu>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <DisclosureButton class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400
                            hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dbblue-500"
          >
            <span class="sr-only">Hauptmenü öffnen</span>
            <MenuIcon v-if="!open" aria-hidden="true" class="block h-6 w-6"/>
            <XIcon v-else aria-hidden="true" class="block h-6 w-6"/>
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <DisclosureButton v-for="item in navigation"
                          :key="item.name"
                          :aria-current="item.current ? 'page' : undefined"
                          :class="[item.current ? 'bg-dbblue-50 border-dbblue-500 text-dbblue-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800', 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium']"
                          :href="item.href"
                          as="a"
        >
          {{ item.name }}
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {BellIcon, MenuIcon, XIcon} from '@heroicons/vue/outline';
import {Link, usePage} from "@inertiajs/vue3";
import {computed} from 'vue';
import Logo from "../Logo.vue";

export default {
  setup() {
    let user = computed(() => usePage().props.auth.user);
    let userGroup = computed(() => usePage().props.auth.userGroup);

    return {user, userGroup};
  },
  data() {
    return {
      userProfile: {
        imageUrl: '/img/no-profile.png'
      },

      userNavigation: [
        {name: 'Mein Profil', href: route('profil.index')},
        {name: 'Ausloggen', href: route('auth.logout')}
      ],

      navigation: [
        {name: 'Startseite', route: 'home'},
        {name: 'Kontakt', route: 'kontakt.index'}
      ],

      authenticatedUserNavigation: [
        {name: 'Mein Bereich', route: 'my_area.index'}
      ],

      managerNavigation: [
        {name: 'Benutzer', route: 'benutzer.index'},
        {name: 'Registrierungscodes', route: 'register-codes.index'},
        {name: 'Ergebnisse', route: 'learning_overview'}
      ],

      adminNavigation: [
        {name: 'Benutzer', route: 'benutzer.index'},
        {name: 'Kurse', route: 'course_admin.index'},
        {name: 'Registrierungscodes', route: 'register-codes.index'},
        {name: 'Standorte', route: 'locations.index'},
        {name: 'Code Statistik', route: 'register_codes_used.index'},
        {name: 'Ergebnisse', route: 'learning_overview'}
      ]
    }
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    MenuIcon,
    XIcon,
    Link,
    Logo
  }
}
</script>
